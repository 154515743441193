export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const ADJUST_LOADING_STATE = 'ADJUST_LOADING_STATE';
export const ADJUST_ERROR_STATE = 'ADJUST_ERROR_STATE';
export const SET_CARD_SELECT_LIST = 'SET_CARD_SELECT_LIST';
export const SET_BLOG_LIST = 'SET_BLOG_LIST';
export const SET_DEMO_CONFIG = 'SET_DEMO_CONFIG';
export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA';
export const SET_REVIEWS_LIST = 'SET_REVIEWS_LIST';
export const SET_REVIEW_CARD_DATA = 'SET_REVIEW_CARD_DATA';
export const SET_REVIEW_CATEGORIES = 'SET_REVIEW_CATEGORIES';
export const SET_GUIDE_CATEGORIES = 'SET_GUIDE_CATEGORIES';

export const initDemoConfig = {
  demoType: '',
  categoryTotals: {
    travel: 0,
    foodAndDrink: 0,
    shops: 0,
    service: 0,
    recreation: 0,
    community: 0,
    healthcare: 0,
  },
  customValues: {
    flights: '', // 22001000 Airlines_cat
    hotels: '', // 22012000 Lodging_cat
    commute: '', // 22014000 PublicTransport_cat
    gas: '', // 22009000 Gas_cat
    restaurants: '', // 13000000 Food_cat
    foodDelivery: '', // 18021000 FoodDelivery_cat
    amazon: '', // 19019000 Digital_cat
    groceries: '', // 19025000 FoodStore_cat
    office: '', // 19039000
    shopsOther: '', // 19000000 Shops_cat
    advertising: '', // 18001000
    shipping: '', // 18058000
    internetAndCable: '', // 18063000
    onlineSearch: '', // 18001006
    streaming: '', // 18061000 Subscription_cat
    serviceOther: '', // 18000000 Service_cat
    gym: '', // 17018000 Gyms_cat
    recreationOther: '', // 17000000 Recreation_cat
    communityOther: '', // 12000000 Community_cat
    healthcareOther: '', // 14000000 Healthcare_cat
  },
  savedSpend: null,
  availableLinkedCards: [],
};

const initialState = {
  currentUser: null,
  activeAccounts: [],
  appLoading: false,
  appError: false,
  cardSelectList: [],
  blogList: [],
  reviewsList: [],
  reviewCardData: [],
  demoConfig: initDemoConfig,
  campaignData: null,
  reviewCategories: [],
  guideCategories: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload.user,
      };

    case REMOVE_USER:
      return {
        ...state,
        currentUser: null,
      };

    case SET_ACCOUNT_DATA:
      return {
        ...state,
        activeAccounts: action.payload.accountData,
      };

    case SET_CARD_SELECT_LIST:
      return {
        ...state,
        cardSelectList: action.payload.cardList,
      };

    case ADJUST_LOADING_STATE:
      return {
        ...state,
        appLoading: action.payload.appLoading,
      };

    case ADJUST_ERROR_STATE:
      return {
        ...state,
        appError: action.payload.appError,
      };

    case SET_REVIEW_CATEGORIES:
      return {
        ...state,
        reviewCategories: action.payload,
      };

    case SET_GUIDE_CATEGORIES:
      return {
        ...state,
        guideCategories: action.payload,
      };

    case SET_BLOG_LIST:
      return {
        ...state,
        blogList: action.payload,
      };

    case SET_REVIEWS_LIST:
      return {
        ...state,
        reviewsList: action.payload,
      };

    case SET_REVIEW_CARD_DATA:
      return {
        ...state,
        reviewCardData: action.payload,
      };

    case SET_DEMO_CONFIG:
      return {
        ...state,
        demoConfig: {
          ...state.demoConfig,
          ...action.payload,
        },
      };
    case SET_CAMPAIGN_DATA:
      return {
        ...state,
        campaignData: action.payload.campaignData,
      };
    default:
      return state;
  }
};

export default reducer;
