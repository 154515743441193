import React from 'react';

const RewardsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Featured icon">
      <path id="fox" d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB"/>
      <g id="Icon">
        <path id="Icon_2" d="M20.2578 7.75C19.5547 7.75 18.9297 8.10156 18.5391 8.6875L16.7812 11.5H17.25H20.375C21.3906 11.5 22.25 10.6797 22.25 9.625C22.25 8.60938 21.3906 7.75 20.375 7.75H20.2578ZM15.1797 11.5L13.4219 8.6875C13.0312 8.10156 12.4062 7.75 11.7031 7.75H11.625C10.5703 7.75 9.75 8.60938 9.75 9.625C9.75 10.6797 10.5703 11.5 11.625 11.5H14.75H15.1797ZM16 10.4453L17.4844 8.02344C18.1094 7.08594 19.125 6.5 20.2578 6.5H20.375C22.0938 6.5 23.5 7.90625 23.5 9.625C23.5 10.3281 23.2656 10.9922 22.875 11.5H24.125C25.1406 11.5 26 12.3594 26 13.375V15.875C26 16.6953 25.4531 17.3984 24.75 17.6719V17.75V24C24.75 25.4062 23.6172 26.5 22.25 26.5H9.75C8.34375 26.5 7.25 25.4062 7.25 24V17.75V17.6719C6.50781 17.3984 6 16.6953 6 15.875V13.375C6 12.3594 6.82031 11.5 7.875 11.5H9.125C8.73438 10.9922 8.5 10.3281 8.5 9.625C8.5 7.90625 9.86719 6.5 11.625 6.5H11.7031C12.8359 6.5 13.8906 7.08594 14.4766 8.02344L16 10.4453ZM11.625 12.75H7.875C7.52344 12.75 7.25 13.0625 7.25 13.375V15.875C7.25 16.2266 7.52344 16.5 7.875 16.5H8.5H15.375V12.75H14.75H11.625ZM16.625 12.75V16.5H23.5H24.125C24.4375 16.5 24.75 16.2266 24.75 15.875V13.375C24.75 13.0625 24.4375 12.75 24.125 12.75H20.375H17.25H16.625ZM15.375 17.75H8.5V24C8.5 24.7031 9.04688 25.25 9.75 25.25H15.375V17.75ZM16.625 25.25H22.25C22.9141 25.25 23.5 24.7031 23.5 24V17.75H16.625V25.25Z" fill="#342876"/>
      </g>
    </g>
  </svg>
);

export default RewardsIcon;
