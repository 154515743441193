import React from 'react';
import {
  Box, Text, Button,
} from 'grommet';
import { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { LightSecondaryButton } from './TopSection';
import { noOrphans } from '../../../utils';
import copy from '../../../utils/copy.json';

const { welcome } = copy;

const BottomForm = ({
  isSmall,
  isMedium,
}) => {
  const isMediumDown = isMedium || isSmall;
  const navigate = useNavigate();

  return (
    <Box
      background="cfPurple600"
      pad="large"
      align="center"
      fill="vertical"
    >
      <Box className="max-page-content">
        <Box
          direction={isMediumDown ? 'column' : 'row'}
          gap="medium"
          justify="between"
        >
          <Box gap="medium" width={{ max: '80%' }}>
            <Text color="cfWhite" weight={600} size="xxlarge">
              {noOrphans(welcome.bottomFormTextOne)}
            </Text>
            <Text color="cfWhite">
              {noOrphans(welcome.bottomFormTextTwo)}
            </Text>
          </Box>
          <Box direction="row-responsive" gap="medium">
            <Button
              alignSelf={isSmall ? 'stretch' : 'start'}
              primary
              as="a"
              label={<Text size="smedium" weight={600} color="cfNeutral900">{welcome.demoButtonCta}</Text>}
              href="calculator/form"
              style={{ textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                amplitude.getInstance().logEvent('welcome-page.try-demo-button.click');
                window.scroll(0, 0);
              }}
            />
            <Box
              as="a"
              href="calculator/form"
              style={{ textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                amplitude.getInstance().logEvent('welcome-page.try-demo-button.click');
                navigate('login');
              }}
            >
              <LightSecondaryButton
                label={<Text size="smedium">{welcome.getStarted}</Text>}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomForm;

BottomForm.propTypes = {
  isSmall: bool,
  isMedium: bool,
};

BottomForm.defaultProps = {
  isSmall: false,
  isMedium: false,
};
