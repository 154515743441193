import React, { useState } from 'react';
import {
  Box, Button, DropButton, Text,
} from 'grommet';
import { Menu as MenuIcon, Close as CloseIcon } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import { bool } from 'prop-types';
import colors from '../styles/colors';
import NavButtonMobile from './NavButtonMobile';
// import AuthButton from './AuthButton';

const itemsLoggedIn = [
  { path: '/dashboard', label: 'Dashboard' },
  { path: '/calculator/selector', label: 'Card Finder' },
  { path: '/reviews', label: 'Credit Card Reviews' },
  { path: '/guides', label: 'Guides' },
  { path: '/news', label: 'News' },
  { path: '/settings', label: 'Settings' },
];

const itemsLoggedOut = [
  { path: '/calculator/selector', label: 'Card Finder' },
  { path: '/reviews', label: 'Credit Card Reviews' },
  { path: '/guides', label: 'Guides' },
  { path: '/news', label: 'News' },
];

const MobileNavigationMenu = ({ isLoggedIn, isSmall }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openPanel, setOpenPanel] = useState(null); // State to track the open accordion panel

  const navItems = isLoggedIn ? itemsLoggedIn : itemsLoggedOut;

  const onCloseMenu = () => {
    setIsOpen(false);
    setOpenPanel(null);
  };

  const renderMenu = () => (
    <Box
      elevation="large"
      width="100vw"
      background="cfWhite"
      gap="small"
      margin={{ top: 'two' }}
    >
      <Box
        gap="small"
        pad={{ bottom: 'small' }}
        border={{ side: 'bottom', size: 'small' }}
        fill="vertical"
        justify="between"
      >
        <Box>
          {navItems.map((item) => (
            <NavButtonMobile
              key={item.path}
              to={item.path}
              label={item.label}
              isOpen={openPanel === item.path}
              onClick={() => {
                onCloseMenu();
                navigate(item.path);
              }}
              onToggle={(isPanelOpen) => {
                setOpenPanel(isPanelOpen ? item.path : null); // Toggle panel open state
              }}
            />
          ))}
        </Box>
        <Box pad="small" gap="small">
          {/* <AuthButton */}
          {/*  onClick={() => onCloseMenu()} */}
          {/*  isMobile={true} */}
          {/*  isLoggedIn={isLoggedIn} */}
          {/*  enableLogout={true} */}
          {/* /> */}
          <Button
            fill={isSmall ? 'horizontal' : ''}
            primary
            size="small"
            href="/"
            onClick={() => {
              onCloseMenu();
              navigate('/');
            }}
            label={<Text size="small" weight={600}>Try Calculator</Text>}
            as="a"
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <DropButton
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => onCloseMenu()}
        dropContent={renderMenu()}
        dropProps={{
          align: { top: 'bottom', left: 'left' },
        }}
      >
        <Button
          icon={
            isOpen
              ? <CloseIcon color={colors.cfWhite} />
              : <MenuIcon color={colors.cfWhite} />
          }
        />
      </DropButton>
    </Box>
  );
};

export default MobileNavigationMenu;

MobileNavigationMenu.propTypes = {
  isLoggedIn: bool,
  isSmall: bool,
};

MobileNavigationMenu.defaultProps = {
  isLoggedIn: false,
  isSmall: false,
};
