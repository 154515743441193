/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Text,
} from 'grommet';
import { bool } from 'prop-types';
import copy from '../../../utils/copy.json';

const faceImageUrls = [
  'https://img.askcleverfox.com/imagery/circle1.png',
  'https://img.askcleverfox.com/imagery/circle2.png',
  'https://img.askcleverfox.com/imagery/circle3.png',
  'https://img.askcleverfox.com/imagery/circle4.png',
  'https://img.askcleverfox.com/imagery/circle5.png',
];

const CirclePeople = ({ isSmall, isMedium }) => (
  <Box
    className="max-page-content"
    background="white"
    fill="horizontal"
    direction={isMedium || isSmall ? 'column' : 'row'}
    align="center"
    justify="around"
    gap="medium"
    pad={{ vertical: 'medium', horizontal: 'small' }}
  >
    <Box direction="row" key="1">
      {faceImageUrls.map((url, idx) => (
        <Box
          key={`${url}_${idx}`}
          height="60px"
          width="60px"
          round="full"
          background={{
            image: `url(${url})`,
          }}
          border={{
            size: '2px',
            color: 'cfGreen500',
          }}
        />
      ))}
    </Box>
    <Box
      width={isSmall || isMedium ? '85%' : '60%'}
      key="2"
    >
      <Text size="medium">
        {copy.welcome.circleImageTextOne}
        <Text size="medium" weight={600} color="cfGreen700">
          {copy.welcome.circleImageTextTwo}
        </Text>
        {copy.welcome.circleImageTextThree}
      </Text>
    </Box>
  </Box>
);

export default CirclePeople;

CirclePeople.propTypes = {
  isSmall: bool,
  isMedium: bool,
};

CirclePeople.defaultProps = {
  isSmall: false,
  isMedium: false,
};
