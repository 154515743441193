import React from 'react';
import dayjs from 'dayjs';

// eslint-disable-next-line no-nested-ternary,import/prefer-default-export
export const sortBy = (key) => (a, b) => ((a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));

export const noop = () => {};

export const formatCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const noOrphans = (string) => {
  // A little safeguard: simply return if the data passed isn't suitable
  if (!string || string.length === 0) {
    return string;
  }

  // Trim whitespace and then split into an array of words
  const words = string.trim().split(' ');

  // Count the number of words
  const totalWords = words.length;

  // If there are fewer than four words, then don't do anything -
  // otherwise, we will end up with an orphan on the first
  // line instead!
  if (totalWords < 4) {
    return string;
  }

  // Replace the last two words with a <nobr> element
  words[totalWords - 2] = `<nobr>${words[totalWords - 2]}`;
  words[totalWords - 1] = `${words[totalWords - 1]}</nobr>`;

  // Join the words back together with a space
  const stringWithNoOrphans = words.join(' ');

  const innerHtml = { __html: stringWithNoOrphans };
  return <span dangerouslySetInnerHTML={innerHtml} />;
};

export const capitalizeWords = (str) => {
  // Split the string into an array of words
  const words = str.split(' ');

  // Iterate over each word in the array using forEach
  words.forEach((word, index, array) => {
    // Capitalize the first letter of the word
    // eslint-disable-next-line no-param-reassign
    array[index] = word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a single string
  const capitalizedStr = words.join(' ');

  return capitalizedStr;
};

export const formatCardNameString = (string) => string && string
  .replaceAll('&#174;', '®')
  .replaceAll(/&#8480;/g, '℠')
  .replaceAll(/&#8482;/g, '™')
  .replaceAll(/_/g, ' ');

export const sendCustomEvent = (eventData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...eventData });
};

export const hyphenateWords = (words) => words && words.trim().toLowerCase().replace(/\s+/g, '-');

export const dehyphenateWords = (words) => words && words.trim().replace(/-/g, ' ');

export const getFullDate = (dateString) => {
  try {
    const published = dayjs(dateString);
    return published.format('MMMM D, YYYY');
  } catch {
    return '';
  }
};
