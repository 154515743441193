import React from 'react';

const ExcellentScoreIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 9C7.71944 9 4 12.8847 4 18H1C1 11.5174 5.78715 6 12 6C18.2128 6 23 11.5174 23 18H20C20 12.8847 16.2806 9 12 9Z" fill="url(#paint0_linear_2441_28596)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 19H0V18C0 11.0654 5.13958 5 12 5C18.8604 5 24 11.0654 24 18V19H19V18C19 13.3456 15.6412 10 12 10C8.35881 10 5 13.3456 5 18V19ZM1.03814 17C1.50028 10.96 6.1093 6 12 6C17.8907 6 22.4997 10.96 22.9619 17C22.9871 17.3303 23 17.6639 23 18H20C20 12.8847 16.2806 9 12 9C7.71944 9 4 12.8847 4 18H1C1 17.6639 1.01287 17.3303 1.03814 17Z" fill="#474F5C" />
    <path d="M17.7288 14.0386C17.7288 12.3817 19.072 11.0386 20.7288 11.0386C22.3857 11.0386 23.7288 12.3817 23.7288 14.0386C23.7288 15.6954 22.3857 17.0386 20.7288 17.0386C19.072 17.0386 17.7288 15.6954 17.7288 14.0386Z" fill="#474F5C" />
    <path d="M18.7285 14.0391C18.7285 12.9345 19.6239 12.0391 20.7285 12.0391C21.8331 12.0391 22.7285 12.9345 22.7285 14.0391C22.7285 15.1436 21.8331 16.0391 20.7285 16.0391C19.6239 16.0391 18.7285 15.1436 18.7285 14.0391Z" fill="white" />
    <path d="M17.2697 14.6723L11.9256 16.0547L12.5734 17.9468L17.6434 15.7636C17.9022 15.6521 18.0266 15.3434 17.9284 15.0564C17.8301 14.7694 17.5426 14.6018 17.2697 14.6723Z" fill="#474F5C" />
    <path d="M12.5734 17.9468C13.0959 17.7679 13.3745 17.1993 13.1956 16.6768C13.0167 16.1543 12.4481 15.8758 11.9256 16.0547C11.403 16.2336 11.1245 16.8022 11.3034 17.3247C11.4823 17.8472 12.0509 18.1257 12.5734 17.9468Z" fill="#474F5C" />
    <path d="M13.2469 16.933C13.2094 16.382 12.7322 15.9658 12.1812 16.0033C11.6302 16.0409 11.214 16.518 11.2516 17.069C11.2891 17.62 11.7663 18.0363 12.3173 17.9987C12.8683 17.9611 13.2845 17.484 13.2469 16.933Z" fill="#474F5C" />
    <defs>
      <linearGradient id="paint0_linear_2441_28596" x1="0.97389" y1="18" x2="23.0261" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DA2531" />
        <stop offset="0.214216" stopColor="#F19663" />
        <stop offset="0.379133" stopColor="#FFDC83" />
        <stop offset="0.597249" stopColor="#BAD88B" />
        <stop offset="0.791426" stopColor="#03CD9F" />
        <stop offset="1" stopColor="#018265" />
      </linearGradient>
    </defs>
  </svg>

);

export default ExcellentScoreIcon;
