/* eslint-disable arrow-body-style */
import React from 'react';
import { bool } from 'prop-types';
import {
  Box,
  Heading,
  Image,
  Paragraph,
} from 'grommet';
import copy from '../../../utils/copy.json';
import ShowRewardsIcon from '../../../assets/icons/ShowRewardsIcon';

const HowItWorks = ({ isSmall, isMediumDown }) => {
  return (
    <Box
      background="cfPurple50"
      align="center"
      fill="horizontal"
      pad="large"
    >
      <Box
        className="max-page-content"
        fill="horizontal"
      >
        <Box
          align="center"
          justify="center"
          pad="small"
        >
          <Heading level="2" size="large" color="cfPurple900" weight={700}>
            {copy.welcome.stepOneHeading}
          </Heading>
        </Box>

        <Box gap="medium">
          {/* Section One */}
          <Box
            direction={isMediumDown ? 'column' : 'row'}
            justify="around"
            align="center"
            fill="vertical"
          >
            <Box
              justify="center"
              width={{ max: isMediumDown ? '' : '45%' }}
            >
              <Heading level="3" margin="none">
                {copy.welcome.stepOneTitle}
              </Heading>
              <Paragraph color="cfNeutral700">
                {copy.welcome.stepOneText}
              </Paragraph>
            </Box>
            <Box
              align="center"
              justify="center"
            >
              <Box
                height={{ max: isSmall ? '300px' : 'medium' }}
                width={{ max: isSmall ? '375px' : '500px' }}
                justify="center"
                align="center"
                pad={isSmall ? 'medium' : 'none'}
              >
                <Image
                  src="https://img.askcleverfox.com/imagery/how-it-works-01.png"
                  alt="Analyze Credit Card Usage"
                  width={isSmall ? 300 : ''}
                />
              </Box>
            </Box>
          </Box>

          {/* Section Two */}
          <Box
            direction={isMediumDown ? 'column' : 'row-reverse'}
            justify="around"
            align="center"
          >
            <Box
              justify="center"
              width={{ max: isMediumDown ? '' : '45%' }}
            >
              <Heading level="3" margin="none">
                {copy.welcome.stepTwoTitle}
              </Heading>
              <Paragraph color="cfNeutral700">
                {copy.welcome.stepTwoText}
              </Paragraph>
            </Box>
            <Box
              align="center"
              justify="center"
            >
              <Box
                height={{ max: isSmall ? '300px' : 'medium' }}
                width={{ max: isSmall ? '375px' : '500px' }}
                justify="center"
                align="center"
                pad={isSmall ? 'medium' : 'none'}
              >
                <Image
                  src="https://img.askcleverfox.com/imagery/how-it-works-02.png"
                  alt="Compare Credit Cards"
                  width={isSmall ? 300 : ''}
                />
              </Box>
            </Box>
          </Box>

          {/* Section Three */}
          <Box
            direction={isMediumDown ? 'column' : 'row'}
            justify="around"
            align="center"
          >
            <Box
              justify="center"
              width={{ max: isMediumDown ? '' : '45%' }}
            >
              <Heading level="3" margin="none">
                {copy.welcome.stepThreeTitle}
              </Heading>
              <Paragraph color="cfNeutral700">
                {copy.welcome.stepThreeText}
              </Paragraph>
            </Box>
            <Box
              align="center"
              justify="center"
            >
              <Box
                pad={{ top: isSmall ? 'medium' : '' }}
                height={{ max: '320px' }}
                width={isSmall ? 'large' : '500px'}
                align="center"
                justify="center"
              >
                <ShowRewardsIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HowItWorks;

HowItWorks.propTypes = {
  isSmall: bool,
  isMediumDown: bool,
};

HowItWorks.defaultProps = {
  isSmall: false,
  isMediumDown: false,
};
