import React from 'react';

const IntroOfferIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Featured icon">
      <path id="fox" d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB"/>
      <g id="Icon">
        <path id="Icon_2" d="M13.9688 7.75C14.4375 7.75 14.9453 7.98438 15.2969 8.33594L22.0156 15.0547C22.9922 16.0312 22.9922 17.6328 22.0156 18.6094L16.8203 23.8047C15.8438 24.7812 14.2422 24.7812 13.2656 23.8047L6.54688 17.0859C6.19531 16.7344 6 16.2266 6 15.7578V9.625C6 8.60938 6.82031 7.75 7.875 7.75H13.9688ZM7.40625 16.1875L14.1641 22.9453C14.6328 23.4141 15.4531 23.4141 15.9219 22.9453L21.1562 17.7109C21.625 17.2422 21.625 16.4219 21.1562 15.9531L14.3984 9.19531C14.2812 9.07812 14.125 9 13.9688 9H7.875C7.52344 9 7.25 9.3125 7.25 9.625V15.7578C7.25 15.9141 7.28906 16.0703 7.40625 16.1875ZM18.0312 7.94531C18.2656 7.71094 18.6562 7.71094 18.9297 7.94531L24.4375 13.2188C26.5078 15.1719 26.5078 18.4922 24.4375 20.4453L19.5547 25.0938C19.3203 25.3281 18.9297 25.3281 18.6953 25.0547C18.4609 24.8203 18.4609 24.4297 18.6953 24.1953L23.5781 19.5469C25.1016 18.0625 25.1016 15.6016 23.5781 14.1172L18.0312 8.84375C17.7969 8.60938 17.7969 8.21875 18.0312 7.94531ZM10.0625 10.875C10.5703 10.875 11 11.3047 11 11.8125C11 12.3594 10.5703 12.75 10.0625 12.75C9.51562 12.75 9.125 12.3594 9.125 11.8125C9.125 11.3047 9.51562 10.875 10.0625 10.875Z" fill="#342876"/>
      </g>
    </g>
  </svg>
);

export default IntroOfferIcon;
