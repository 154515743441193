/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Image,
} from 'grommet';

const bankIcons = [
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/American-Express-BW.png',
    alt: 'American Express',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Bank-of-America-BW.png',
    alt: 'Bank of America',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Barclays-BW.png',
    alt: 'Barclay',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Capital-One-BW.png',
    alt: 'Capital One',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Chase-Bank-BW.png',
    alt: 'Chase Bank',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Citi-BW.png',
    alt: 'Citi',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Discover-BW.png',
    alt: 'Discover Cards',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/US-Bank-BW.png',
    alt: 'US Bank',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/TD-Bank-BW.png',
    alt: 'TD Bank',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/USAA-BW.png',
    alt: 'USAA',
  },
  {
    url: 'https://img.askcleverfox.com/imagery/bank-logos/Wells-Fargo-BW.png',
    alt: 'Wells Fargo',
  },
];

const BankListSection = () => (
  <Box
    background="cfWhite"
    align="center"
    fill="horizontal"
    justify="center"
    gap="large"
    pad={{ horizontal: 'large', vertical: 'xlarge' }}
    className="hide-scrollbar"
  >
    <Box direction="row" gap="medium" overflow="scroll">
      {bankIcons.map((icon, idx) => (
        <Box
          key={`bank_icon_${idx}`}
          height="35px"
          width={{ min: '150px' }}
        >
          <Image src={icon.url} fit="contain" alt={icon.alt} loading="lazy" />
        </Box>
      ))}
    </Box>
  </Box>
);

export default BankListSection;
