import {
  SET_USER,
  REMOVE_USER,
  SET_ACCOUNT_DATA,
  ADJUST_LOADING_STATE,
  ADJUST_ERROR_STATE,
  SET_CARD_SELECT_LIST,
  SET_BLOG_LIST,
  SET_DEMO_CONFIG,
  SET_CAMPAIGN_DATA,
  SET_REVIEWS_LIST,
  SET_REVIEW_CARD_DATA,
  SET_REVIEW_CATEGORIES,
  SET_GUIDE_CATEGORIES,
} from './index';

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

export const removeUser = () => ({
  type: REMOVE_USER,
});

export const adjustLoadingState = (loadingPayload) => ({
  type: ADJUST_LOADING_STATE,
  payload: loadingPayload,
});

export const adjustErrorState = (errorPayload) => ({
  type: ADJUST_ERROR_STATE,
  payload: errorPayload,
});

export const setActiveAccounts = (accountData) => ({
  type: SET_ACCOUNT_DATA,
  payload: { accountData },
});

export const setCardSelectList = (cardList) => ({
  type: SET_CARD_SELECT_LIST,
  payload: { cardList },
});

export const setGuideCategories = (categories) => ({
  type: SET_GUIDE_CATEGORIES,
  payload: categories,
});

export const setReviewCategories = (categories) => ({
  type: SET_REVIEW_CATEGORIES,
  payload: categories,
});

export const setBlogList = (blogList) => ({
  type: SET_BLOG_LIST,
  payload: blogList,
});

export const setReviewsList = (reviewsList) => ({
  type: SET_REVIEWS_LIST,
  payload: reviewsList,
});

export const setReviewCardData = (reviewCardData) => ({
  type: SET_REVIEW_CARD_DATA,
  payload: reviewCardData,
});

export const setDemoConfig = (demoConfig) => ({
  type: SET_DEMO_CONFIG,
  payload: demoConfig,
});

export const setCampaignData = (campaignData) => ({
  type: SET_CAMPAIGN_DATA,
  payload: { campaignData },
});
