import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import {
  Box, Text,
  Button, Menu,
  Grommet,
} from 'grommet';
import { FormDown, FormUp } from 'grommet-icons';
import { useNavigate, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components';
import { adjustErrorState, setGuideCategories, setReviewCategories } from '../reducers/actions';
import { hyphenateWords, noop } from '../utils';
import client from '../pages/blog/client';
import reviewClient from '../pages/reviews/client';
import colors from '../styles/colors';

const menuCustomTheme = {
  menu: {
    icons: {
      color: colors.cfWhite,
      down: FormDown,
      up: FormUp,
    },
    extend: () => css`
      z-index: 102
    `,
  },
};

export const getGuideCategories = async (routeType, date, direction) => {
  const lastPublishedAt = date || '2222-12-29T19:10:22.897Z';
  const compare = direction === 'prev' ? '>' : '<';

  const groupCondition = routeType ? '&& group match "guides"' : '';

  const query = `*[_type == "post" && (
      publishedAt ${compare} $lastPublishedAt
      || (publishedAt == $lastPublishedAt)
    ) ${groupCondition}]
    | order(dateTime(coalesce(publishedAt, now())) desc) {
      _id,
      categories[]->,
  }`;

  const posts = await client.fetch(query, { lastPublishedAt });
  return posts;
};

export const getReviewCategories = async () => {
  const query = `*[_type == "review"] 
  | order(dateTime(coalesce(publishedAt, now())) desc){
      _id,
      categories[]->,
    }`;

  const reviewData = await reviewClient.fetch(query);
  return reviewData;
};

const NavButton = ({ to, label, onClick }) => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewCategoriesFromState = useSelector((state) => state.reviewCategories);
  const guideCategoriesFromState = useSelector((state) => state.guideCategories);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let fetchedCategories = [];
        if (label.toLowerCase() === 'credit card reviews') {
          fetchedCategories = reviewCategoriesFromState.length > 0
            ? reviewCategoriesFromState
            : await getReviewCategories();
          dispatch(setReviewCategories(fetchedCategories));
        } else if (label.toLowerCase() === 'guides') {
          fetchedCategories = guideCategoriesFromState.length > 0
            ? guideCategoriesFromState
            : await getGuideCategories();
          dispatch(setGuideCategories(fetchedCategories));
        }

        const uniqueTitles = [
          ...new Set(
            fetchedCategories
              .filter((item) => item.categories) // Filter out items where categories is null
              .flatMap((item) => item.categories.map((category) => {
                if (label.toLowerCase() === 'guides' && category.title.startsWith('News ')) {
                  return null;
                }
                return category.title;
              }))
              .filter(Boolean),
          ),
        ];

        setCategories(uniqueTitles);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [label]);

  const pushToRoute = async () => {
    dispatch(adjustErrorState(false));
    onClick();
    await navigate(`${to}`, { replace: true });
  };

  const match = useMatch({
    path: `${to}/*`,
    end: false,
  });

  const borderConfig = match
    ? {
      color: colors.cfWhite,
      size: 'small',
      style: 'solid',
      side: 'bottom',
    }
    : false;

  const handleCategoryClick = (category) => {
    navigate(`${to}/credit-cards/${hyphenateWords(category)}`, { replace: true });
  };

  const menuItems = [
    {
      label: label?.toLowerCase() === 'credit card reviews'
        ? <Text size="small">All Reviews</Text>
        : <Text size="small">All Guides</Text>,
      onClick: () => pushToRoute(),
    },
    ...categories.map((categoryTitle) => ({
      label: <Text size="small">{categoryTitle}</Text>,
      onClick: () => handleCategoryClick(categoryTitle),
    })),
  ];

  const renderButtonContent = () => (
    <Box border={borderConfig} align="start" pad={{ vertical: 'xsmall' }}>
      <Text color={colors.cfWhite} weight={400} size="small">
        {label}
      </Text>
    </Box>
  );

  return (
    <Box>
      {categories.length > 0 ? (
        <Grommet theme={menuCustomTheme}>
          <Menu
            className="navDropMenu"
            dropBackground={colors.cfWhite}
            label={renderButtonContent()}
            items={menuItems}
            dropProps={{ align: { top: 'bottom', left: 'left' } }}
          />
        </Grommet>
      ) : (
        <Box pad={{ right: 'small' }}>
          <Button
            plain
            as="a"
            href={to}
            onClick={(e) => {
              e.preventDefault();
              pushToRoute();
            }}
          >
            {renderButtonContent()}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const WrappedNavButton = (props) => <NavButton {...props} />;

export default WrappedNavButton;

NavButton.propTypes = {
  to: string.isRequired,
  label: string,
  onClick: func,
};

NavButton.defaultProps = {
  onClick: noop,
  label: '',
};
