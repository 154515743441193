import React from 'react';
import {
  Anchor,
  Box,
  Footer,
  Text,
  Button,
  ResponsiveContext,
} from 'grommet';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
} from '../assets/icons';
import copy from '../utils/copy.json';

const { footer } = copy;

export const AppFooter = () => {
  const size = React.useContext(ResponsiveContext);
  const { pathname } = useLocation();
  const isSmall = size === 'small';
  const navigate = useNavigate();

  if (pathname.includes('calculator/form') || pathname.includes('calculator/selector')) {
    return null;
  }

  return (
    <Footer
      background="cfPurple600"
      pad={{ bottom: 'medium', horizontal: 'medium' }}
      fill="horizontal"
    >
      <Box fill="horizontal" justify="center" align="center">
        <Box
          border={{ side: 'top', color: 'cfPurple400' }}
          pad={{ top: 'medium' }}
          className="max-page-content"
          fill="horizontal"
          direction="row"
          justify="between"
        >
          <Box
            align="start"
            direction="row-responsive"
          >
            <Button
              as="a"
              plain
              style={{ padding: '12px' }}
              href="/about"
              onClick={() => navigate('/about')}
            >
              <Text color="cfWhite" className="textUnderline" size="xsmall">About</Text>
            </Button>
            <Button
              as="a"
              plain
              style={{ padding: '12px' }}
              href="/faq"
              onClick={() => navigate('/faq')}
            >
              <Text color="cfWhite" className="textUnderline" size="xsmall">FAQ</Text>
            </Button>
            <Anchor
              color="white"
              href="https://img.askcleverfox.com/pdf/privacy-notice-12-02-22.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ padding: '12px' }}
            >
              <Text
                className="textUnderline"
                size="xsmall"
                weight={400}
              >
                Privacy Policy
              </Text>
            </Anchor>
            <Anchor
              color="white"
              href="https://img.askcleverfox.com/pdf/terms-of-service-12-02-22.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ padding: '12px' }}
            >
              <Text
                className="textUnderline"
                size="xsmall"
                weight={400}
              >
                Terms of Service
              </Text>
            </Anchor>
            <Anchor
              href={`mailto:${footer.emailAddress}`}
              target="_blank"
              style={{ padding: '12px' }}
            >
              <Text
                size="xsmall"
                color="white"
                weight={400}
                className="textUnderline"
              >
                {footer.email}
              </Text>
            </Anchor>
          </Box>
          <Box align="center" gap="small">
            <Box
              direction="row-responsive"
              gap="small"
              align="center"
              justify="center"
            >
              <Anchor
                href="https://www.facebook.com/askcleverfox"
                aria-label="Facebook link"
                target="_blank"
                icon={<FacebookIcon />}
              />
              <Anchor
                href="https://www.instagram.com/askcleverfox"
                aria-label="Instagram link"
                target="_blank"
                icon={<InstagramIcon />}
              />
              <Anchor
                href="https://www.tiktok.com/@askcleverfox"
                aria-label="TikTok link"
                target="_blank"
                icon={<TikTokIcon />}
              />
              <Anchor
                href="https://www.linkedin.com/company/askcleverfox"
                aria-label="Contact us link"
                target="_blank"
                icon={<LinkedInIcon />}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className="max-page-content"
          direction="row-responsive"
          gap="medium"
          justify="between"
          align="end"
          pad={{ bottom: 'small', top: isSmall ? 'medium' : 'none' }}
        >
          <Box gap="xsmall" width={isSmall ? '100%' : '70%'}>
            <Text color="cfNeutral300" size="xsmall">
              {footer.disclaimerBodyOne}
            </Text>
            <Text color="cfNeutral300" size="xsmall">
              {footer.disclaimerBodyTwo}
            </Text>
            <Text color="cfNeutral300" size="xsmall">
              {footer.disclaimerBodyThree}
            </Text>
          </Box>
          <Text color="cfWhite" size="xsmall">
            {footer.copyright}
          </Text>
        </Box>
      </Box>
    </Footer>
  );
};

export default AppFooter;
