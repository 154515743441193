/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:d2d669a6-423b-4b66-be72-b0cb23b2d1fb",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_hDVF0B0io",
    "aws_user_pools_web_client_id": "2b4g16porpnrukctmfa1kof3ae",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cf-app-storage152117-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "DemoSimulatedResults-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "Liability-prod",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://hbwrjgimzzdlrdapgbwwrjzpfy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2uowjoftpvcqvngf6wucrr7gta"
};


export default awsmobile;
