import React from 'react';
import {
  ResponsiveContext,
  Box, Header,
  Image,
} from 'grommet';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavButton from './NavButton';
// import AuthButton from './AuthButton';
import MobileNavigationMenu from './MobileNav';

const logoImage = 'https://img.askcleverfox.com/imagery/cf-logo.png';

const getNavigationItems = (isLoggedIn, getMobileLabel, navigate) => {
  const itemsLoggedIn = [
    // { path: '/leaderboard', label: 'Leaderboard' },
    { path: '/calculator/selector', label: 'Card Finder' },
    { path: '/reviews', label: 'Credit Card Reviews' },
    { path: '/guides', label: 'Guides' },
    { path: '/news', label: 'News' },
    { path: '/settings', label: 'Settings' },
  ];

  const itemsLoggedOut = [
    // { path: '/leaderboard', label: 'Leaderboard' },
    { path: '/calculator/selector', label: 'Card Finder' },
    { path: '/reviews', label: 'Credit Card Reviews' },
    { path: '/guides', label: 'Guides' },
    { path: '/news', label: 'News' },
  ];

  const items = isLoggedIn ? itemsLoggedIn : itemsLoggedOut;

  return items.map((item) => ({
    label: item.label,
    path: item.path,
    onClick: (e) => {
      e.preventDefault();
      navigate(item.path);
    },
  }));
};

export const AppHeaderComp = ({ isLoggedIn }) => {
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isMediumDown = isSmall || isMedium;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const renderDesktopNav = () => {
    const navItems = getNavigationItems(isLoggedIn, (label) => label, navigate);

    return (
      <Box justify="between" direction="row" gap="medium" pad={{ horizontal: 'small' }}>
        <Box direction="row" gap="small">
          {navItems.map((item) => item.path && (
            <NavButton
              key={item.path}
              to={item.path}
              label={item.label}
            />
          ))}
        </Box>
        {/* <Box direction="row" gap="small"> */}
        {/*  <AuthButton isLoggedIn={isLoggedIn} isOnDark={true} /> */}
        {/* </Box> */}
      </Box>
    );
  };

  return (
    <Header background="cfPurple600" id="app-header" pad={{ vertical: 'small' }} align="center">
      <Link
        to={isLoggedIn ? '/dashboard' : '/'}
        key="1234"
        style={{ cursor: pathname.includes('dashboard') ? 'default' : '' }}
      >
        <Box height="30px" width="160px" margin={{ left: isMediumDown ? '10px' : '20px' }}>
          <Image src={logoImage} alt="CleverFox" />
        </Box>
      </Link>
      <Box fill={!isMediumDown}>
        {isMediumDown
          ? <MobileNavigationMenu isLoggedIn={isLoggedIn} isSmall={isSmall} />
          : renderDesktopNav()}
      </Box>
    </Header>
  );
};

AppHeaderComp.propTypes = {
  isLoggedIn: PropTypes.bool,
};

AppHeaderComp.defaultProps = {
  isLoggedIn: false,
};

export default AppHeaderComp;
