import React from 'react';

const CreditBuilderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB" />
    <path d="M7.25 9C6.54688 9 6 9.58594 6 10.25V11.5H26V10.25C26 9.58594 25.4141 9 24.75 9H7.25ZM6 12.75V15.25H26V12.75H6ZM6 16.5V22.75C6 23.4531 6.54688 24 7.25 24H24.75C25.4141 24 26 23.4531 26 22.75V16.5H6ZM4.75 10.25C4.75 8.88281 5.84375 7.75 7.25 7.75H24.75C26.1172 7.75 27.25 8.88281 27.25 10.25V22.75C27.25 24.1562 26.1172 25.25 24.75 25.25H7.25C5.84375 25.25 4.75 24.1562 4.75 22.75V10.25ZM8.5 20.875C8.5 20.5625 8.77344 20.25 9.125 20.25H11.625C11.9375 20.25 12.25 20.5625 12.25 20.875C12.25 21.2266 11.9375 21.5 11.625 21.5H9.125C8.77344 21.5 8.5 21.2266 8.5 20.875ZM13.5 20.875C13.5 20.5625 13.7734 20.25 14.125 20.25H19.125C19.4375 20.25 19.75 20.5625 19.75 20.875C19.75 21.2266 19.4375 21.5 19.125 21.5H14.125C13.7734 21.5 13.5 21.2266 13.5 20.875Z" fill="#342876" />
  </svg>
);

export default CreditBuilderIcon;
