import React from 'react';

const LimitedScoreIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 9C7.71944 9 4 12.8847 4 18H1C1 11.5174 5.78715 6 12 6C18.2128 6 23 11.5174 23 18H20C20 12.8847 16.2806 9 12 9Z" fill="url(#paint0_linear_2441_28575)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 19H0V18C0 11.0654 5.13958 5 12 5C18.8604 5 24 11.0654 24 18V19H19V18C19 13.3456 15.6412 10 12 10C8.35881 10 5 13.3456 5 18V19ZM1.03814 17C1.50028 10.96 6.1093 6 12 6C17.8907 6 22.4997 10.96 22.9619 17C22.9871 17.3303 23 17.6639 23 18H20C20 12.8847 16.2806 9 12 9C7.71944 9 4 12.8847 4 18H1C1 17.6639 1.01287 17.3303 1.03814 17Z" fill="#474F5C" />
    <path d="M0.287526 13.9376C0.287526 12.2807 1.63067 10.9376 3.28753 10.9376C4.94438 10.9376 6.28753 12.2807 6.28753 13.9376C6.28753 15.5944 4.94438 16.9376 3.28753 16.9376C1.63067 16.9376 0.287526 15.5944 0.287526 13.9376Z" fill="#474F5C" />
    <path d="M1.28711 13.9375C1.28711 12.8329 2.18254 11.9375 3.28711 11.9375C4.39168 11.9375 5.28711 12.8329 5.28711 13.9375C5.28711 15.0421 4.39168 15.9375 3.28711 15.9375C2.18254 15.9375 1.28711 15.0421 1.28711 13.9375Z" fill="white" />
    <path d="M6.97929 14.6704L12.3235 16.0527L11.6756 17.9449L6.60567 15.7616C6.34681 15.6502 6.22238 15.3414 6.32064 15.0545C6.4189 14.7675 6.70643 14.5998 6.97929 14.6704Z" fill="#474F5C" />
    <path d="M11.6756 17.9449C11.1531 17.766 10.8746 17.1974 11.0535 16.6749C11.2324 16.1524 11.801 15.8738 12.3235 16.0527C12.846 16.2316 13.1245 16.8002 12.9456 17.3227C12.7667 17.8452 12.1981 18.1238 11.6756 17.9449Z" fill="#474F5C" />
    <path d="M11.0021 16.931C11.0396 16.38 11.5168 15.9638 12.0678 16.0014C12.6188 16.0389 13.035 16.5161 12.9974 17.0671C12.9599 17.6181 12.4827 18.0343 11.9317 17.9967C11.3807 17.9592 10.9645 17.482 11.0021 16.931Z" fill="#474F5C" />
    <defs>
      <linearGradient id="paint0_linear_2441_28575" x1="0.97389" y1="18" x2="23.0261" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DA2531" />
        <stop offset="0.214216" stopColor="#F19663" />
        <stop offset="0.379133" stopColor="#FFDC83" />
        <stop offset="0.597249" stopColor="#BAD88B" />
        <stop offset="0.791426" stopColor="#03CD9F" />
        <stop offset="1" stopColor="#018265" />
      </linearGradient>
    </defs>
  </svg>
);

export default LimitedScoreIcon;
