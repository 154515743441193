import React from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
} from 'grommet';
import { bool } from 'prop-types';
import copy from '../../../utils/copy.json';
import colors from '../../../styles/colors';

const securityBg = 'https://img.askcleverfox.com/imagery/bg_pattern05.png';

const Security = ({ isSmall, isMedium }) => {
  const isSingleColumn = isSmall || isMedium;

  return (
    <Box
      align="center"
      pad="large"
      background={{
        image: `url(${securityBg}), linear-gradient(180deg, #F2F5FA 0%, #F2FCFA 100%)`,
        color: `${colors.cfNeutral50}`,
        size: 'cover',
      }}
    >
      <Box className="max-page-content">
        <Box
          align="center"
          justify="center"
          pad={{ bottom: 'large' }}
        >
          <Heading level="2" size="large" color="cfPurple900" weight={700}>
            {copy.welcome.securityHeading}
          </Heading>
          <Text
            color="cfNeutral700"
            margin={{ bottom: 'small' }}
            size="smedium"
            textAlign="center"
          >
            {copy.welcome.securitySubHeading}
          </Text>
        </Box>
        <Box
          gap="large"
          direction={isSingleColumn ? 'column' : 'row'}
        >
          <Box
            align="start"
            justify="center"
            pad="small"
            fill="vertical"
          >
            <Box height="56px" width="56px">
              <Image
                src="https://img.askcleverfox.com/imagery/secureconnection.png"
                alt="Secure Connection"
                loading="lazy"
              />
            </Box>
            <Heading
              color="cfPurple700"
              level="3"
              size="small"
              margin={{ vertical: 'small' }}
            >
              {copy.welcome.securityConnectionHeading}
            </Heading>
            <Text color="cfNeutral700" size="smedium">
              {copy.welcome.securityConnectionText}
            </Text>
          </Box>
          <Box
            align="start"
            justify="center"
            pad="small"
            fill="vertical"
          >
            <Box height="56px" width="56px">
              <Image
                src="https://img.askcleverfox.com/imagery/dataencryption.png"
                alt="Data Encryption"
                loading="lazy"
              />
            </Box>
            <Heading
              color="cfPurple700"
              level="3"
              size="small"
              margin={{ vertical: 'small' }}
            >
              {copy.welcome.securityEncryptionHeading}
            </Heading>
            <Text color="cfNeutral700" size="smedium">
              {copy.welcome.securityEncryptionText}
            </Text>
          </Box>
          <Box
            align="start"
            justify="center"
            pad="small"
            fill="vertical"
          >
            <Box height="56px" width="56px">
              <Image
                src="https://img.askcleverfox.com/imagery/cloudinfrastructure.png"
                alt="Cloud Infrastructure"
                loading="lazy"
              />
            </Box>
            <Heading
              color="cfPurple700"
              level="3"
              size="small"
              margin={{ vertical: 'small' }}
            >
              {copy.welcome.securityInfraHeading}
            </Heading>
            <Text color="cfNeutral700" size="smedium">
              {copy.welcome.securityInfraText}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Security;

Security.propTypes = {
  isSmall: bool.isRequired,
  isMedium: bool.isRequired,
};
