import { adjustErrorState, setUser } from './reducers/actions';
import { getIsAppError, getIsAppLoading } from './selectors';

export const mapStateToProps = (state) => ({
  appLoading: getIsAppLoading(state),
  appError: getIsAppError(state),
});

export const mapDispatchToProps = (dispatch) => ({
  setUser: (authData) => dispatch(setUser(authData)),
  clearAppError: () => dispatch(adjustErrorState(false)),
});
