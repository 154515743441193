import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { css } from 'styled-components';
import colors from './colors';

const customTheme = deepMerge(grommet, {
  global: {
    colors,
    breakpoints: {
      small: {
        value: 600,
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '3px',
          xsmall: '4px',
          small: '8px',
          medium: '14px',
          large: '26px',
          xlarge: '50px',
        },
      },
      medium: {
        value: 900,
      },
      large: {
        value: 1200,
      },
      xlarge: {
        value: 1600,
      },
    },
    font: {
      family: 'Montserrat',
    },
    edgeSize: {
      two: '2px',
      xxsmall: '4px',
      xsmall: '8px',
      small: '16px',
      large: '36px',
      xlarge: '48px',
      xxlarge: '60px',
      xxxlarge: '96px',
    },
  },
  accordion: {
    icons: {
      color: colors.cfPurple600,
    },
  },
  button: {
    default: {
      ...grommet.button.default,
      color: colors.cfTextStrong,
      padding: {
        horizontal: '12px',
        vertical: '8px',
      },
    },
    primary: {
      color: colors.cfTextStrong,
      background: {
        color: colors.cfGreen500,
      },
      border: {
        radius: '8px',
        color: colors.cfGreen600,
        width: '1px',
      },
      padding: {
        horizontal: '12px',
        vertical: '8px',
      },
    },
    secondary: {
      border: {
        radius: '8px',
        width: '1px',
      },
      color: colors.cfPurple700,
      background: {
        color: 'transparent',
      },
      padding: {
        horizontal: '12px',
        vertical: '8px',
      },
    },
    plain: {
      padding: {
        horizontal: '12px',
        vertical: '8px',
      },
    },
    hover: {
      default: {
        background: {
          color: colors.cfPurple600,
        },
      },
      primary: {
        color: colors.cfPurple500,
        background: colors.cfWhite,
      },
      secondary: {
        color: colors.cfWhite,
        background: colors.cfPurple500,
      },
      plain: {
        color: colors.cfWhite,
        background: colors.cfPurple700,
      },
    },
  },
  anchor: {
    extend: ({ underline }) => css`
      text-decoration: ${underline ? 'underline' : ''};
    `,
  },
  heading: {
    level: {
      1: {
        large: {
          size: '56px',
          height: '64px',
        },
        medium: {
          size: '48px',
          height: '60px',
        },
        small: {
          size: '32px',
          height: '22px',
        },
        xsmall: {
          size: '20px',
          height: '36px',
        },
      },
      2: {
        xsmall: {
          size: '16px',
          height: '16px',
        },
        small: {
          size: '24px',
          height: '36px',
        },
        medium: {
          size: '32px',
          height: '36px',
        },
        large: {
          size: '40px',
          height: '42px',
        },
      },
      3: {
        medium: {
          size: '28px',
        },
        small: {
          size: '20px',
        },
        xsmall: {
          size: '16px',
        },
      },
      4: {
        medium: {
          size: '20px',
        },
        small: {
          size: '18px',
        },
        xsmall: {
          size: '12px',
        },
      },
      5: {
        medium: {
          size: '16px',
        },
      },
    },
  },
  text: {
    extend: ({ underline, italic }) => css`
      text-decoration: ${underline && 'underline'};
      font-style: ${italic && 'italic'};
    `,
    xsmall: {
      size: '12px',
      height: '16px',
    },
    small: {
      size: '14px',
    },
    smedium: {
      size: '16px',
    },
    medium: {
      size: '18px',
      height: '28px',
    },
    large: {
      size: '24px',
    },
    xlarge: {
      size: '28px',
    },
    xxlarge: {
      size: '32px',
    },
  },
});

export default customTheme;
