import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import amplitude from 'amplitude-js';
import rootReducer from './reducers';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore(
  rootReducer,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
const apiKey = process.env.REACT_APP_PLAID_ENV === 'production' ? 'bdd1e19b92a5435b6448ec1102efa6ee' : 'API_KEY';
amplitude.getInstance().init(apiKey);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

/* BELOW IS TESTING FOR PRE-RENDER WITH REACT-SNAP */
// const rootElement = document.getElementById('root');
//
// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(
//     <React.StrictMode>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </React.StrictMode>,
//     rootElement,
//   );
// } else {
//   ReactDOM.render(
//     <React.StrictMode>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </React.StrictMode>,
//     rootElement,
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
