import React, { useContext } from 'react';
import {
  Heading,
  Text,
  Accordion,
  AccordionPanel,
  Box, Anchor,
  ResponsiveContext,
} from 'grommet';
import { bool } from 'prop-types';
import copy from '../../utils/copy.json';

const { faq } = copy;

const FaqPage = ({ shouldShowShortList }) => {
  const faqItems = shouldShowShortList ? faq.shortItems : faq.items;
  const size = useContext(ResponsiveContext);
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isMediumDown = isMedium || isSmall;

  return (
    <Box
      fill
      margin={{ vertical: shouldShowShortList ? 'none' : 'medium' }}
      pad={{ horizontal: 'small', top: 'large', bottom: 'xlarge' }}
      responsive={false}
      className="max-page-content"
      direction={isMediumDown ? 'column' : 'row'}
      gap="medium"
    >
      <Heading level="2" size="large" color="cfPurple900" weight={700} margin="none">
        {faq.pageHeading}
      </Heading>
      <Accordion fill>
        {faqItems.map((item) => (
          <AccordionPanel label={(
            <Box pad={{ vertical: 'small' }}>
              <Text size="smedium">{item.title}</Text>
            </Box>
          )}
          >
            <Box pad={{ bottom: 'medium', horizontal: 'xsmall' }}>
              <Text size="small">{item.content}</Text>
              {item.email && (
                <Anchor
                  label={item.email}
                  href={`mailto:${item.email}`}
                  target="_blank"
                  color="cleverOrange"
                />
              )}
            </Box>
          </AccordionPanel>
        ))}
      </Accordion>
    </Box>
  );
};

export default FaqPage;

FaqPage.propTypes = {
  shouldShowShortList: bool,
};

FaqPage.defaultProps = {
  shouldShowShortList: false,
};
