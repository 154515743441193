import { createSelector } from 'reselect';
import dayjs from 'dayjs';

const getState = (state) => state;

export const getUserFromState = createSelector([getState], (state) => state.currentUser);

export const getActiveAccountsFromState = createSelector(
  [getState],
  (state) => {
    let sortedAccounts = [];
    const accounts = state?.activeAccounts;
    if (accounts && accounts.length) {
      sortedAccounts = accounts.sort((a, b) => {
        const compareA = dayjs(b.createdAt).unix();
        const compareB = dayjs(a.createdAt).unix();

        return compareA - compareB;
      });
    }
    return sortedAccounts;
  },
);

export const getIsAppLoading = createSelector(
  [getState],
  (state) => state.appLoading,
);

export const getIsAppError = createSelector(
  [getState],
  (state) => state.appError,
);

export const getCardListFromState = createSelector(
  [getState],
  (state) => state.cardSelectList,
);

export const getAppLoadingState = createSelector(
  [getState],
  (state) => state.appLoading,
);

export const getCampaignData = createSelector(
  [getState],
  (state) => state.campaignData,
);
