const textColor = '#3B3E47';

const coreColors = {
  text: textColor,
  cfTextStrong: '#28262E',
  cfTextWeak: '#57555C',
  border: '#DBDADD',
  cfWhite: '#FFF',
  cfGrey500: '#667085',
  cfGreen500: '#03CD9F',
  cfGreen600: '#02A983',
  cfGreen700: '#018265',
  cfMint50: '#F2FCFA',
  cfNeutral50: '#F6F6F7',
  cfNeutral100: '#DEE0E3',
  cfNeutral300: '#A1A4AD',
  cfNeutral500: '#696E79',
  cfNeutral600: '#515660',
  cfNeutral700: textColor,
  cfNeutral800: '#26282F',
  cfNeutral900: '#1B1D23',
  cfPurple50: '#F2F5FA',
  cfPurple100: '#CED8E8',
  cfPurple300: '#7984B4',
  cfPurple400: '#555796',
  cfPurple500: '#342876',
  cfPurple600: '#312370',
  cfPurple700: '#181144',
  cfSuccess100: '#CAFFE2',
  cfSuccess900: '#054F27',
  cfCritical100: '#FAE8E7',
  cfCritical500: '#B33224',
  cfWarning100: '#FBF8DE',
};

export default coreColors;
