import React from 'react';
import {
  Box, Card,
  Heading,
  Paragraph, Text,
} from 'grommet';
import copy from '../../../utils/copy.json';
import { ReactComponent as SingleQuoteIcon } from '../../../assets/welcomePage/quote-icon.svg';
import colors from '../../../styles/colors';

const testimonialsBg = 'https://img.askcleverfox.com/imagery/bg_pattern_04.png';

const {
  welcome: {
    testimonialHeading,
    testimonialOneCopy,
    testimonialOneName,
    testimonialTwoCopy,
    testimonialTwoName,
    testimonialThreeCopy,
    testimonialThreeName,
    testimonialFourCopy,
    testimonialFourName,
    testimonialFiveCopy,
    testimonialFiveName,
    testimonialSixCopy,
    testimonialSixName,
  },
} = copy;

const getTestimonialItem = (itemCopy, image, name) => (
  <Card
    background="white"
    fill
    className="borderRadiusEight"
    justify="between"
    pad="medium"
  >
    <Box>
      <SingleQuoteIcon />
    </Box>
    <Box basis="auto" justify="start">
      <Paragraph color="cfNeutral700">
        <Text size="smedium" color="cfNeutral700">
          {itemCopy}
        </Text>
      </Paragraph>
    </Box>
    <Box
      direction="row"
      gap="small"
      align="center"
    >
      <Box
        height="60px"
        width="60px"
        round="full"
        background={{
          image: `url(${image})`,
        }}
        border={{
          size: '2px',
          color: 'cfGreen500',
        }}
      />
      <Text size="smedium" color="cfNeutral700" weight={600}>{name}</Text>
    </Box>
  </Card>
);

const Testimonials = () => (
  <Box
    background={{
      image: `url(${testimonialsBg})`,
      color: `${colors.cfPurple50}`,
      size: 'cover',
    }}
    align="center"
    pad="large"
  >
    <Box className="max-page-content">
      <Box
        align="center"
        justify="center"
        pad={{ bottom: 'large' }}
      >
        <Heading level="2" size="large" color="cfPurple900" weight={700}>
          {testimonialHeading}
        </Heading>
      </Box>
      <Box direction="row-responsive">
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialOneCopy,
            'https://img.askcleverfox.com/imagery/testimonial1.png',
            testimonialOneName,
          )}
        </Box>
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialTwoCopy,
            'https://img.askcleverfox.com/imagery/testimonial2.png',
            testimonialTwoName,
          )}
        </Box>
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialThreeCopy,
            'https://img.askcleverfox.com/imagery/testimonial3.png',
            testimonialThreeName,
          )}
        </Box>
      </Box>
      <Box direction="row-responsive">
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialFourCopy,
            'https://img.askcleverfox.com/imagery/testimonial4.png',
            testimonialFourName,
          )}
        </Box>
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialFiveCopy,
            'https://img.askcleverfox.com/imagery/testimonial5.png',
            testimonialFiveName,
          )}
        </Box>
        <Box
          align="start"
          justify="center"
          pad="small"
        >
          {getTestimonialItem(
            testimonialSixCopy,
            'https://img.askcleverfox.com/imagery/testimonial6.png',
            testimonialSixName,
          )}
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Testimonials;
