import React from 'react';

const FoodAndDrinkIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9922 3.72852C19.3047 4.1582 19.2266 4.74414 18.7969 5.05664L13.9922 8.65039C13.9141 8.72852 13.7969 8.80664 13.7188 8.88477L13.6406 8.96289C13.1719 9.43164 12.9375 10.0566 12.9375 10.6816C12.9375 11.3457 13.1719 11.9707 13.6406 12.4395L13.875 12.6738L14.1484 12.9473C14.6172 13.416 15.2422 13.6504 15.9062 13.6504C16.5312 13.6504 17.1562 13.416 17.625 12.9473L17.7031 12.8691C17.7812 12.791 17.8594 12.6738 17.9375 12.5957L21.5312 7.79102C21.8438 7.36133 22.4297 7.2832 22.8594 7.5957C23.2891 7.9082 23.3672 8.49414 23.0547 8.92383L19.4609 13.7285C19.3047 13.8848 19.1875 14.041 19.0312 14.1973L18.9531 14.2754C18.1328 15.0957 17.0391 15.5645 15.9062 15.5645C14.9297 15.5645 13.9922 15.2129 13.25 14.666L4.85156 23.0645C4.46094 23.4551 3.875 23.4551 3.52344 23.0645C3.13281 22.7129 3.13281 22.127 3.52344 21.7754L11.9219 13.3379C11.375 12.5957 11.0625 11.6582 11.0625 10.6816C11.0625 9.54883 11.4922 8.45508 12.3125 7.63477L12.3906 7.55664C12.5469 7.40039 12.7031 7.2832 12.8984 7.12695L17.6641 3.5332C18.0938 3.2207 18.6797 3.29883 18.9922 3.72852ZM21.1016 5.48633C21.4531 5.87695 21.4531 6.46289 21.1016 6.81445L16.5703 11.3457C16.1797 11.7363 15.5938 11.7363 15.2422 11.3457C14.8516 10.9941 14.8516 10.4082 15.2422 10.0566L19.7734 5.48633C20.125 5.13477 20.7109 5.13477 21.0625 5.48633H21.1016ZM15.6719 16.7754L20.4766 21.7754C20.8281 22.127 20.8281 22.7129 20.4375 23.1035C20.0859 23.4551 19.4609 23.4551 19.1094 23.0645L12.9766 16.6973L13.4453 16.2285C14.1484 16.5801 14.8906 16.7754 15.6719 16.7754ZM9.92969 13.5723L2.625 5.95508V6.61914C2.625 8.68945 3.40625 10.6816 4.89062 12.127L7.625 14.9004C7.82031 15.0566 8.01562 15.1738 8.25 15.2129L6.84375 16.6582C6.64844 16.541 6.45312 16.3848 6.29688 16.1895L3.5625 13.4551C1.72656 11.6191 0.75 9.19727 0.75 6.61914V4.74414C0.75 3.96289 1.33594 3.33789 2.11719 3.33789C2.46875 3.33789 2.82031 3.49414 3.09375 3.76758L9.8125 10.7207C9.8125 11.541 10.0078 12.4004 10.3594 13.1426L9.92969 13.5723Z" fill="#323841" />
  </svg>

);

export default FoodAndDrinkIcon;
