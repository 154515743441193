import React from 'react';
import {
  Box, Heading, Text,
  ResponsiveContext,
} from 'grommet';
import { Helmet } from 'react-helmet';
import copy from '../../utils/copy.json';
import DemoCardSelector from './DemoCardSelector';
import { noOrphans } from '../../utils';

const DemoCardSelectorWrapper = () => {
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isMediumDown = isMedium || isSmall;
  const background = 'https://img.askcleverfox.com/imagery/hero-pattern.png';

  return (
    <Box
      fill
      background={{ image: `url(${background})`, size: 'cover' }}
      height={{ min: '100vh' }}
      pad={{ horizontal: isMediumDown ? 'large' : 'xlarge' }}
      align="center"
      justify="center"
    >
      <Helmet>
        <title>Cleverfox | Calculator</title>
        <meta name="description" content="Cleverfox | Calculator" />
      </Helmet>
      <Box
        direction={isMediumDown ? 'column' : 'row'}
        gap="xlarge"
        justify="between"
        align="center"
        width={{ max: 'xxlarge' }}
      >
        <Box
          gap="large"
          width={{ max: isMediumDown ? '100%' : '45%' }}
        >
          <Heading color="cfWhite" level="1" margin="none">
            {copy.welcome.topHeading}
          </Heading>
          <Box>
            <Text size="medium" color="cfWhite">
              {copy.welcome.topSubHeadingTop}
            </Text>
            <Text size="medium" color="cfWhite">
              {copy.welcome.topSubHeadingOne}
              <Text size="medium" weight={600} color="cfGreen500">
                {copy.welcome.topSubHeadingTwo}
              </Text>
              {noOrphans(copy.welcome.topSubHeadingThree)}
            </Text>
          </Box>
        </Box>
        <Box
          fill
          align="center"
          alignSelf="center"
          justify="center"
        >
          <Box
            justify="center"
            align="center"
            flex="grow"
            fill
            width={{ max: '650px' }}
          >
            <DemoCardSelector />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DemoCardSelectorWrapper;
