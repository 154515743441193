import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import amplitude from 'amplitude-js';
import { Authenticator, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box, Text, Anchor,
} from 'grommet';
import { Helmet } from 'react-helmet';
import copy from '../../utils/copy.json';
import { mapDispatchToProps, mapStateToProps } from '../../App.config';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

const { login } = copy;

const getPasswordLabel = () => (
  <Box gap="xsmall" width="80%">
    <Text size="small">Password</Text>
    <Text
      size="10px"
      color="cfTextStrong"
      weight={500}
    >
      {login.passwordRules}
    </Text>
  </Box>
);

const components = {
  SignUp: {
    FormFields() {
      const checkboxLabel = (
        <Box
          direction="row-responsive"
          align="center"
          gap="xsmall"
        >
          <Box>
            <Text size="xsmall">
              I agree to the
            </Text>
          </Box>
          <Box direction="row" gap="xsmall" align="center">
            <Box margin={{ bottom: '3px' }}>
              <Anchor
                target="_blank"
                href="https://img.askcleverfox.com/pdf/terms-of-service-12-02-22.pdf"
              >
                <Text
                  size="xsmall"
                  color="cfGreen500"
                  margin={{ bottom: '4px' }}
                >
                  Terms of Service
                </Text>
              </Anchor>
            </Box>
            <Text size="xsmall">
              &
            </Text>
            <Box margin={{ bottom: '4px' }}>
              <Anchor
                target="_blank"
                href="https://img.askcleverfox.com/pdf/privacy-notice-12-02-22.pdf"
              >
                <Text
                  size="xsmall"
                  color="cfGreen500"
                >
                  Privacy Policy
                </Text>
              </Anchor>
            </Box>
          </Box>
        </Box>
      );

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            isRequired={true}
            name="acknowledgement"
            value="yes"
            label={checkboxLabel}
          />
        </>
      );
    },
  },
  ConfirmResetPassword: {
    FormFields() {
      return (
        <Authenticator.ConfirmResetPassword.FormFields />
      );
    },
  },
};

const formFields = {
  signUp: {
    given_name: {
      label: <Text size="small">First Name</Text>,
      order: 1,
      isRequired: true,
      placeholder: 'First Name',
      labelHidden: false,
    },
    family_name: {
      label: <Text size="small">Last Name</Text>,
      order: 2,
      isRequired: true,
      placeholder: 'Last Name',
      labelHidden: false,
    },
    email: {
      label: <Text size="small">Email</Text>,
      order: 3,
      isRequired: true,
      placeholder: 'Email Address',
      labelHidden: false,
    },
    password: {
      label: getPasswordLabel(),
      order: 5,
      isRequired: true,
      labelHidden: false,
    },
    confirm_password: {
      label: <Text size="small">Confirm Password</Text>,
      order: 6,
      isRequired: true,
      labelHidden: false,
    },
  },

  confirmResetPassword: {
    confirmation_code: {
      order: 1,
      label: <Text size="small">Confirmation Code</Text>,
      labelHidden: false,
      isRequired: true,
    },
    password: {
      label: getPasswordLabel(),
      order: 2,
      isRequired: true,
      labelHidden: false,
    },
    confirm_password: {
      label: <Text size="small">Confirm Password</Text>,
      order: 3,
      isRequired: true,
      labelHidden: false,
    },
  },
  confirmSignUp: {
    confirmation_code: {
      label: (<Box pad={{ bottom: 'small' }}><Text>Also, check your spam folder just in case.</Text></Box>),
      isRequired: true,
      labelHidden: false,
    },
  },
};

const services = {
  async handleSignUp(formData) {
    amplitude.getInstance().logEvent('login.sign-up.click');
    return Auth.signUp(formData);
  },
  async handleSignIn(formData) {
    amplitude.getInstance().logEvent('login.sign-in.click');
    return Auth.signIn(formData);
  },
  async handleForgotPassword(formData) {
    amplitude.getInstance().logEvent('login.forgot-pwd.click');
    return Auth.forgotPassword(formData);
  },
};

const Login = ({ setUser }) => {
  // pass context.route to prevent re-rendering
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialState = searchParams.get('view') === 'create' ? 'signUp' : '';
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        amplitude.getInstance().setUserId(currentUser.username);
      }).then(() => {
        navigate('/dashboard', { replace: true });
      })
      .catch((err) => {
        // not authenticated
        console.log(err);
      });
  }, [route, navigate]);

  return (
    <Box
      margin={{ vertical: 'xlarge' }}
      align="center"
      justify="center"
    >
      <Helmet>
        <title>Cleverfox | Login</title>
        <meta name="description" content="Cleverfox | Login" />
      </Helmet>
      <Authenticator
        initialState={initialState}
        services={services}
        formFields={formFields}
        components={components}
      />
    </Box>
  );
};

Login.propTypes = {
  setUser: func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
