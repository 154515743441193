import React, { useState, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import {
  Box, Text,
  Button, Accordion,
  AccordionPanel,
} from 'grommet';
import { useNavigate, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adjustErrorState, setGuideCategories, setReviewCategories } from '../reducers/actions';
import { hyphenateWords, noop } from '../utils';
import colors from '../styles/colors';
import { getGuideCategories, getReviewCategories } from './NavButton';

const NavButtonMobile = ({
  to,
  label,
  onClick,
  isOpen,
  onToggle,
}) => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewCategoriesFromState = useSelector((state) => state.reviewCategories);
  const guideCategoriesFromState = useSelector((state) => state.guideCategories);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let fetchedCategories = [];
        if (label.toLowerCase() === 'credit card reviews') {
          fetchedCategories = reviewCategoriesFromState.length > 0
            ? reviewCategoriesFromState
            : await getReviewCategories();
          dispatch(setReviewCategories(fetchedCategories));
        } else if (label.toLowerCase() === 'guides') {
          fetchedCategories = guideCategoriesFromState.length > 0
            ? guideCategoriesFromState
            : await getGuideCategories();
          dispatch(setGuideCategories(fetchedCategories));
        }

        const uniqueTitles = [
          ...new Set(
            fetchedCategories
              .filter((item) => item.categories) // Filter out items where categories is null
              .flatMap((item) => item.categories.map((category) => category.title)),
          ),
        ];

        setCategories(uniqueTitles);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [label]);

  const pushToRoute = async () => {
    dispatch(adjustErrorState(false));
    onClick();
    await navigate(`${to}`, { replace: true });
  };

  const match = useMatch({
    path: `${to}/*`,
    end: false,
  });

  const borderConfig = match
    ? {
      color: 'cfWhite',
      size: 'small',
      style: 'solid',
      side: 'bottom',
    }
    : false;

  const renderButtonContent = () => (
    <Box align="center" pad="xsmall">
      <Text color={colors.cfPurple500} weight={600} size="small">
        {label}
      </Text>
    </Box>
  );

  return (
    <Box
      background={match ? 'cfPurple50' : ''}
      align="start"
      border={borderConfig}
      fill="horizontal"
    >
      {categories.length > 0 ? (
        <Accordion
          fill
          activeIndex={isOpen ? 0 : -1}
          onActive={(activeIndexes) => onToggle(activeIndexes.includes(0))}
        >
          <AccordionPanel pad="medium" label={renderButtonContent()}>
            <Box
              pad="medium"
              height={{ max: '350px' }}
              overflow={{ vertical: 'auto' }}
              background="cfWhite"
            >
              <Box
                margin="xsmall"
                height={{ min: '40px' }}
              >
                <Button
                  href={`${to}`}
                  plain
                  onClick={pushToRoute}
                >
                  <Text size="small">
                    {label?.toLowerCase() === 'credit card reviews'
                      ? 'All Reviews'
                      : 'All Guides'}
                  </Text>
                </Button>
              </Box>
              <Box>
                {categories.map((categoryTitle) => (
                  <Box
                    key={categoryTitle}
                    margin="xsmall"
                    height={{ min: '40px' }}
                  >
                    <Button
                      href={`${to}/credit-cards/${hyphenateWords(categoryTitle)}`}
                      plain
                      onClick={() => {
                        onClick(); // parent callback
                        navigate(`${to}/credit-cards/${hyphenateWords(categoryTitle)}`);
                      }}
                    >
                      <Text size="small">{categoryTitle}</Text>
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          </AccordionPanel>
        </Accordion>
      ) : (
        <Box fill align="start" pad="medium" border="bottom">
          <Button
            fill
            plain
            as="a"
            href={to}
            onClick={(e) => {
              e.preventDefault();
              pushToRoute();
            }}
          >
            <Box fill="horizontal" align="start">
              {renderButtonContent()}
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};

const WrappedNavButton = (props) => <NavButtonMobile {...props} />;

export default WrappedNavButton;

NavButtonMobile.propTypes = {
  to: string.isRequired,
  label: string,
  onClick: func,
  isOpen: bool,
  onToggle: func,
};

NavButtonMobile.defaultProps = {
  onClick: noop,
  label: '',
  isOpen: false,
  onToggle: noop,
};
