import React from 'react';

const GoodScoreIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 9C7.71944 9 4 12.8847 4 18H1C1 11.5174 5.78715 6 12 6C18.2128 6 23 11.5174 23 18H20C20 12.8847 16.2806 9 12 9Z" fill="url(#paint0_linear_2441_28589)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 19H0V18C0 11.0654 5.13958 5 12 5C18.8604 5 24 11.0654 24 18V19H19V18C19 13.3456 15.6412 10 12 10C8.35881 10 5 13.3456 5 18V19ZM1.03814 17C1.50028 10.96 6.1093 6 12 6C17.8907 6 22.4997 10.96 22.9619 17C22.9871 17.3303 23 17.6639 23 18H20C20 12.8847 16.2806 9 12 9C7.71944 9 4 12.8847 4 18H1C1 17.6639 1.01287 17.3303 1.03814 17Z" fill="#474F5C" />
    <path d="M12.9404 8.50153C12.9404 6.84468 14.2835 5.50153 15.9404 5.50153C17.5972 5.50153 18.9404 6.84468 18.9404 8.50153C18.9404 10.1584 17.5972 11.5015 15.9404 11.5015C14.2835 11.5015 12.9404 10.1584 12.9404 8.50153Z" fill="#474F5C" />
    <path d="M13.9404 8.50195C13.9404 7.39738 14.8359 6.50195 15.9404 6.50195C17.045 6.50195 17.9404 7.39738 17.9404 8.50195C17.9404 9.60652 17.045 10.502 15.9404 10.502C14.8359 10.502 13.9404 9.60652 13.9404 8.50195Z" fill="white" />
    <path d="M13.8032 11.7682L11.0935 16.5775L12.9062 17.4227L14.8485 12.2557C14.9477 11.9919 14.8116 11.6881 14.5366 11.5599C14.2617 11.4317 13.9415 11.5227 13.8032 11.7682Z" fill="#474F5C" />
    <path d="M12.9062 17.4227C13.1396 16.9222 12.923 16.3272 12.4225 16.0938C11.9219 15.8604 11.327 16.0769 11.0935 16.5775C10.8601 17.078 11.0767 17.673 11.5772 17.9064C12.0778 18.1398 12.6728 17.9232 12.9062 17.4227Z" fill="#474F5C" />
    <path d="M12.6427 16.2344C12.2196 15.8794 11.5888 15.9345 11.2338 16.3576C10.8788 16.7807 10.934 17.4115 11.3571 17.7665C11.7802 18.1215 12.4109 18.0663 12.7659 17.6432C13.1209 17.2201 13.0658 16.5894 12.6427 16.2344Z" fill="#474F5C" />
    <defs>
      <linearGradient id="paint0_linear_2441_28589" x1="0.97389" y1="18" x2="23.0261" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DA2531" />
        <stop offset="0.214216" stopColor="#F19663" />
        <stop offset="0.379133" stopColor="#FFDC83" />
        <stop offset="0.597249" stopColor="#BAD88B" />
        <stop offset="0.791426" stopColor="#03CD9F" />
        <stop offset="1" stopColor="#018265" />
      </linearGradient>
    </defs>
  </svg>
);

export default GoodScoreIcon;
