import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Box, Notification, Button } from 'grommet';
import { mapDispatchToProps } from '../../App.config';

export const AppLevelErrorComp = ({ clearAppError }) => {
  const tryAgain = async () => {
    await clearAppError();
    window.location.reload();
  };

  return (
    <Box
      className="borderRadiusEight"
      align="center"
      justify="center"
      background="white"
      pad={{ vertical: 'medium', horizontal: 'small' }}
      elevation="small"
      gap="small"
    >
      <Notification
        status="critical"
        title="Something went wrong"
        message="Click below to refresh"
      />
      <Button
        margin={{ top: 'medium' }}
        size="small"
        primary
        onClick={() => tryAgain()}
        label="Try Again"
      />
    </Box>
  );
};

export default connect(null, mapDispatchToProps)(AppLevelErrorComp);

AppLevelErrorComp.propTypes = {
  clearAppError: func.isRequired,
};
