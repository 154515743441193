import React from 'react';

const CashBackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Featured icon">
      <path id="fox" d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB"/>
      <g id="Icon">
        <path id="Icon_2" d="M6 13.6875C7.36719 13.6875 8.5 12.5938 8.5 11.1875C7.75781 11.1484 7.01562 11.0312 6.3125 10.7969C6.23438 10.7969 6.11719 10.7969 6.03906 10.875C6 10.875 6 10.9141 6 10.9141V13.6875ZM6 21.1875V23.0234C6 23.2969 6.11719 23.4141 6.23438 23.4531C6.97656 23.7266 7.71875 23.8828 8.5 23.9609V23.6875C8.5 22.3203 7.36719 21.1875 6 21.1875ZM6 19.9375C8.07031 19.9375 9.75 21.6172 9.75 23.6875V24C11.4688 23.9609 13.3047 23.5703 15.2578 23.0625C15.4141 23.0234 15.5703 22.9844 15.7656 22.9453C17.7969 22.3984 19.9844 21.8516 22.25 21.8125C22.25 19.7422 23.9297 18.0625 26 18.0625V13.0625C23.9297 13.0625 22.25 11.3828 22.25 9.3125V9C20.4922 9.03906 18.6562 9.46875 16.7031 9.97656C16.5469 10.0156 16.3906 10.0547 16.1953 10.0938C14.1641 10.6406 11.9766 11.1875 9.75 11.2266C9.71094 13.2969 8.03125 14.9375 6 14.9375V19.9375ZM25.6484 22.2031C25.7656 22.2422 25.8438 22.2422 25.9219 22.1641C25.9609 22.1641 25.9609 22.125 26 22.125V19.3125C24.5938 19.3125 23.5 20.4453 23.5 21.8125V21.8516C24.2031 21.8906 24.9453 22.0078 25.6484 22.2031ZM23.5 9.3125C23.5 10.7188 24.5938 11.8125 26 11.8125V10.0156C26 9.74219 25.8438 9.625 25.7266 9.58594C24.9844 9.3125 24.2422 9.11719 23.5 9.07812V9.3125ZM4.75 23.0234V10.9141C4.75 9.97656 5.72656 9.35156 6.625 9.625C9.75 10.4453 12.875 9.66406 16 8.88281C19.3984 7.98438 22.7969 7.125 26.1953 8.41406C26.8203 8.64844 27.25 9.3125 27.25 10.0156V22.125C27.25 23.0625 26.2344 23.6875 25.3359 23.4141C22.2109 22.5938 19.0859 23.375 16 24.1562C12.6016 25.0156 9.20312 25.9141 5.80469 24.625C5.14062 24.3906 4.75 23.7266 4.75 23.0234ZM13.5 16.5C13.5 18.3359 14.7109 19.625 16 19.625C17.25 19.625 18.5 18.3359 18.5 16.5C18.5 14.6641 17.25 13.375 16 13.375C14.7109 13.375 13.5 14.6641 13.5 16.5ZM16 12.125C18.1484 12.125 19.75 14.1953 19.75 16.5C19.75 18.8047 18.1484 20.875 16 20.875C13.8125 20.875 12.25 18.8047 12.25 16.5C12.25 14.1953 13.8125 12.125 16 12.125Z" fill="#342876"/>
      </g>
    </g>
  </svg>
);

export default CashBackIcon;
