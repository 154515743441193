import React from 'react';
import {
  Box, Heading,
  Text, Button,
} from 'grommet';
import { bool } from 'prop-types';
import styled from 'styled-components';
import copy from '../../../utils/copy.json';
import colors from '../../../styles/colors';
import DemoCardSelector from '../../demo/DemoCardSelector';
import { noOrphans } from '../../../utils';

export const LightSecondaryButton = styled(Button)`
  border: ${colors.cfWhite} 1px solid;
  color: ${colors.cfWhite};
  border-radius: 8px;
  &:hover {
    background: ${colors.cfWhite};
    color: ${colors.cfPurple500};
  }
`;

const TopSection = ({ isSmall, isMedium }) => {
  const isMediumDown = isMedium || isSmall;
  const topBackground = 'https://img.askcleverfox.com/imagery/bg_pattern01.png';

  return (
    <Box
      background={{
        image: `url(${topBackground})`,
        color: `${colors.cfPurple500}`,
        size: 'cover',
      }}
      pad={{ horizontal: 'large', vertical: isMediumDown ? 'xlarge' : 'xxxlarge' }}
      align="center"
    >
      <Box
        direction={isMediumDown ? 'column' : 'row'}
        gap="xlarge"
        justify="between"
        align="center"
        width={{ max: 'xxlarge' }}
      >
        <Box
          gap="large"
          width={{ max: isMediumDown ? '100%' : '45%' }}
        >
          <Heading color="cfWhite" level="1" margin="none">
            {noOrphans(copy.welcome.topHeading)}
          </Heading>
          <Box>
            <Text size="medium" color="cfWhite">
              {copy.welcome.topSubHeadingOne}
              <Text size="medium" weight={600} color="cfGreen500">
                {copy.welcome.topSubHeadingTwo}
              </Text>
              {noOrphans(copy.welcome.topSubHeadingThree)}
            </Text>
          </Box>
        </Box>
        <Box
          fill
          align="center"
          alignSelf="center"
          justify="center"
        >
          <Box
            justify="center"
            align="center"
            flex="grow"
            fill
            width={{ max: '650px' }}
          >
            <DemoCardSelector />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopSection;

TopSection.propTypes = {
  isSmall: bool,
  isMedium: bool,
};

TopSection.defaultProps = {
  isSmall: false,
  isMedium: false,
};
