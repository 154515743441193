import React from 'react';
import {
  Grommet,
  Box,
} from 'grommet';
import { datadogRum } from '@datadog/browser-rum';
import { Helmet } from 'react-helmet';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import AppHeader from './common/AppHeader';
import MainContentSection from './common/MainContentSection';
import AppFooterComp from './common/AppFooter';
import customTheme from './styles/theme';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './styles/global.scss';

let endpoint = 'https://rh6klux7h4.execute-api.us-west-2.amazonaws.com/dev'; // TODO: make env variable
if (process.env.REACT_APP_API_ENV === 'test') {
  endpoint = 'https://z3pxu4flol.execute-api.us-west-2.amazonaws.com/test';
} else if (process.env.REACT_APP_API_ENV === 'production') {
  endpoint = 'https://api.askcleverfox.com';
}
const custom = {
  aws_cloud_logic_custom: [
    {
      name: 'cfplaidApiGateway',
      endpoint,
    },
  ],
};

const env = process.env.REACT_APP_API_ENV;
const replaySampleRate = process.env.REACT_APP_DD_SAMPLE_RATE || 0;
if (env === 'test' || env === 'production') {
  datadogRum.init({
    applicationId: 'e08f0b26-540e-4474-84ac-673301fc5de6',
    clientToken: 'pubc3b7f3f2a3496860c32b95ab6ff42d71',
    site: 'datadoghq.com',
    service: 'cfapp',
    env: process.env.REACT_APP_API_ENV,

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: parseInt(replaySampleRate, 10),
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

datadogRum.startSessionReplayRecording();

const updatedAwsConfig = {
  ...awsconfig,
  ...custom,
};

// if (process.env.REACT_APP_API_ENV === 'production') {
// updatedAwsConfig.oauth = {
//   ...awsconfig.oauth,
//   domain: 'auth.askcleverfox.com',
//   redirectSignIn: 'https://www.askcleverfox.com/dashboard/',
//   redirectSignOut: 'https://www.askcleverfox.com/settings/',
//   scope: [
//     'profile', 'email', 'openid',
//   ],
// };
// }

Amplify.configure(updatedAwsConfig);

// anything inside Authenticator is authenticated
const AppComponent = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const isLoggedIn = user && !!user.username;

  return (
    <Grommet theme={customTheme}>
      <Helmet>
        <title>CleverFox Financial</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap" rel="stylesheet" />
        <meta name="description" content="CleverFox is the easiest way to find your most valuable credit card" />
      </Helmet>
      <Box
        className="main-content-container"
        justify="center"
      >
        <BrowserRouter>
          <Box
            className="main-content-container"
            background="white"
            justify="between"
            align="center"
          >
            <AppHeader isLoggedIn={isLoggedIn} />
            <MainContentSection />
            <AppFooterComp isLoggedIn={isLoggedIn} />
          </Box>
        </BrowserRouter>
      </Box>
    </Grommet>
  );
};

// eslint-disable-next-line max-len
const AppComponentWrapped = () => (<Authenticator.Provider><AppComponent /></Authenticator.Provider>);
export default AppComponentWrapped;
