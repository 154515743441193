import React from 'react';

const TravelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Featured icon">
      <path id="fox" d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB"/>
      <g id="Icon">
        <path id="Icon_2" d="M9.67188 10.6406L8.22656 11.3047L7.99219 10.7188L8.22656 11.3047L13.6172 14.625C13.8125 14.7422 13.9297 14.9375 13.8906 15.1719C13.8906 15.4062 13.7734 15.6016 13.5781 15.7188L9.08594 18.0234C8.89062 18.1016 8.69531 18.1016 8.53906 18.0234L6.3125 17.0469C6.11719 16.9688 5.92188 16.9688 5.72656 17.0469L4.82812 17.5938L7.32812 20.0938C7.44531 20.2109 7.60156 20.25 7.75781 20.25H13.1875C13.2656 20.25 13.3828 20.25 13.4609 20.2109L24.1641 14.8594C25.4141 14.1953 26.4688 13.1406 27.0547 11.8516C27.25 11.3828 26.9375 10.875 26.4297 10.875H24.0469C23.3828 10.875 22.6797 11.0703 22.0547 11.3828L18.6172 13.1406C18.4609 13.2188 18.3047 13.2188 18.1484 13.1406L11.1172 10.5625C10.6484 10.4062 10.1406 10.4453 9.67188 10.6406ZM11.5469 9.39062L18.3047 11.8906L21.5078 10.25C22.2891 9.85938 23.1875 9.625 24.0469 9.625H26.4297C27.8359 9.625 28.7734 11.1094 28.1875 12.3594C27.4844 13.9219 26.2344 15.2109 24.7109 15.9922L14.0078 21.3047C13.7734 21.4609 13.4609 21.5 13.1875 21.5H7.75781C7.25 21.5 6.78125 21.3047 6.42969 20.9531L3.92969 18.4922C3.34375 17.9062 3.46094 16.9297 4.20312 16.5L5.14062 15.9922C5.64844 15.6797 6.27344 15.6406 6.82031 15.875L6.58594 16.4219L6.82031 15.875L8.77344 16.7734L12.0156 15.0938L7.60156 12.3594C6.74219 11.8125 6.82031 10.5625 7.71875 10.1719L9.16406 9.50781C9.90625 9.15625 10.7656 9.11719 11.5469 9.39062ZM4.125 25.25H27.875C28.1875 25.25 28.5 25.5625 28.5 25.875C28.5 26.2266 28.1875 26.5 27.875 26.5H4.125C3.77344 26.5 3.5 26.2266 3.5 25.875C3.5 25.5625 3.77344 25.25 4.125 25.25Z" fill="#342876"/>
      </g>
    </g>
  </svg>
);

export default TravelIcon;
