import React, { useContext } from 'react';
import {
  Box, Button,
  Text, Grid,
  ResponsiveContext,
  Anchor,
} from 'grommet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LinkNext } from 'grommet-icons';
import amplitude from 'amplitude-js';
import { useDispatch } from 'react-redux';
import copy from '../../utils/copy.json';
import colors from '../../styles/colors';
import { noOrphans, sendCustomEvent } from '../../utils';
import { setDemoConfig } from '../../reducers/actions';
import { initDemoConfig } from '../../reducers';
import {
  BusinessIcon,
  CarHotelIcon,
  CashBackIcon,
  CreditBuilderIcon,
  IntroOfferIcon,
  NotSureIcon,
  RewardsIcon,
  TravelIcon,
} from '../../assets/icons';

const { demo } = copy;

const mockDashAccounts = [
  {
    demoType: 'Travel',
    demoPath: 'travel',
    demoMask: '-8483',
    icon: CarHotelIcon,
  },
  {
    demoType: 'Miles',
    demoPath: 'miles',
    demoMask: '-8483',
    icon: TravelIcon,
  },
  {
    demoType: 'Rewards',
    demoPath: 'rewards',
    demoMask: '-3345',
    icon: RewardsIcon,
  },
  {
    demoType: 'Cash Back',
    demoPath: 'cashback',
    demoMask: '-1245',
    icon: CashBackIcon,
  },
  {
    demoType: '0% Intro',
    demoPath: 'introapr',
    demoMask: '-8894',
    icon: IntroOfferIcon,
  },
  {
    demoType: 'Business',
    demoPath: 'business',
    demoMask: '-2343',
    icon: BusinessIcon,
  },
  {
    demoType: 'Limited Credit',
    demoPath: 'creditbuilder',
    demoMask: '-2343',
    icon: CreditBuilderIcon,
  },
  {
    demoType: 'Not Sure',
    demoPath: 'notSure',
    demoMask: '-2343',
    icon: NotSureIcon,
  },
];

const StyledDemoButton = styled(Button)`
  padding: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
  border: 1px solid ${colors.cfPurple100};
  border-radius: 8px;
  background: ${colors.cfWhite};
  &:hover {
    background: ${colors.cfNeutral100};
    color: ${colors.text};
  }
  height: ${({ isMobile }) => (isMobile ? '60px' : '80px')};;
`;

const DemoCardSelector = () => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const savedId = window.localStorage.getItem('cleverPreviewId');

  const getDemoButtonHref = ({ demoPath }) => (demoPath === 'notSure'
    ? '/calculator/form'
    : `/calculator/${demoPath || ''}`);

  const onClickDemoButton = ({
    demoMask,
    demoPath,
  }) => {
    if (demoPath === 'notSure') {
      dispatch(setDemoConfig({
        ...initDemoConfig,
      }));
      return navigate('/calculator/form');
    }

    const eventName = 'welcome.demo-button.click';
    const gtmPayload = {
      event: eventName,
      meta: {
        demoType: demoPath,
      },
    };
    amplitude.getInstance().logEvent(eventName);
    sendCustomEvent(gtmPayload);

    return navigate(
      `/calculator/${demoPath || ''}`,
      {
        state: {
          lastFour: demoMask,
        },
      },
    );
  };

  const onClickSavedResults = () => {
    const eventName = 'welcome.saved-results-button.click';
    const gtmPayload = {
      event: eventName,
      meta: {
        savedId,
      },
    };
    amplitude.getInstance().logEvent(eventName);
    sendCustomEvent(gtmPayload);

    return navigate(
      `/calculator/saved?savedId=${savedId}`,
      {
        state: {
          lastFour: '1234',
        },
      },
    );
  };

  const headingCopy = noOrphans(demo.selectorStepOneHeading);

  return (
    <Box
      pad="medium"
      round="16px"
      background={{ color: 'cfWhite' }}
      gap="medium"
      flex="grow"
      fill="horizontal"
    >
      <Box gap="medium">
        <Box gap="xsmall">
          <Text color="cfNeutral700" weight={600}>{headingCopy}</Text>
          {savedId && (
          <Anchor onClick={() => onClickSavedResults()}>
            <Box
              direction="row"
              gap="xsmall"
              justify="start"
            >
              <Text
                size="12px"
                color="cfPurple500"
                weight="500"
                style={{ textDecoration: 'underline' }}
              >
                {copy.demo.selectorSavedResultsCTA}
              </Text>
              <LinkNext color="cfPurple500" size="14px" />
            </Box>
          </Anchor>
          )}
        </Box>
        <Box>
          <Grid
            align="center"
            justify="center"
            columns="1/2"
            gap="small"
          >
            {mockDashAccounts.map((account) => {
              const { demoId, demoType, icon } = account;
              const ButtonIcon = icon;

              return (
                <Box key={demoId} width="100%">
                  <StyledDemoButton
                    as="a"
                    href={getDemoButtonHref(account)}
                    secondary
                    isMobile={isMobile}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickDemoButton(account);
                    }}
                  >
                    <Box
                      fill
                      direction="row"
                      gap="medium"
                      align="center"
                      justify="start"
                    >
                      <Box height={isMobile ? '24px' : '36px'} width={isMobile ? '24px' : '36px'}>
                        <ButtonIcon />
                      </Box>
                      <Text color="cfPurple500" size="small" weight={600}>{demoType}</Text>
                    </Box>
                  </StyledDemoButton>
                </Box>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DemoCardSelector;
