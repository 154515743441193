import React from 'react';

const FairScoreIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 9C7.71944 9 4 12.8847 4 18H1C1 11.5174 5.78715 6 12 6C18.2128 6 23 11.5174 23 18H20C20 12.8847 16.2806 9 12 9Z" fill="url(#paint0_linear_2441_28582)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 19H0V18C0 11.0654 5.13958 5 12 5C18.8604 5 24 11.0654 24 18V19H19V18C19 13.3456 15.6412 10 12 10C8.35881 10 5 13.3456 5 18V19ZM1.03814 17C1.50028 10.96 6.1093 6 12 6C17.8907 6 22.4997 10.96 22.9619 17C22.9871 17.3303 23 17.6639 23 18H20C20 12.8847 16.2806 9 12 9C7.71944 9 4 12.8847 4 18H1C1 17.6639 1.01287 17.3303 1.03814 17Z" fill="#474F5C" />
    <path d="M5.05963 8.5015C5.05963 6.84464 6.40278 5.5015 8.05963 5.5015C9.71649 5.5015 11.0596 6.84464 11.0596 8.5015C11.0596 10.1583 9.71649 11.5015 8.05963 11.5015C6.40278 11.5015 5.05963 10.1583 5.05963 8.5015Z" fill="#474F5C" />
    <path d="M6.05957 8.50195C6.05957 7.39738 6.955 6.50195 8.05957 6.50195C9.16414 6.50195 10.0596 7.39738 10.0596 8.50195C10.0596 9.60652 9.16414 10.502 8.05957 10.502C6.955 10.502 6.05957 9.60652 6.05957 8.50195Z" fill="white" />
    <path d="M10.1968 11.7682L12.9065 16.5775L11.0938 17.4227L9.15145 12.2557C9.05228 11.9919 9.18844 11.6881 9.46336 11.5599C9.73828 11.4317 10.0585 11.5227 10.1968 11.7682Z" fill="#474F5C" />
    <path d="M11.0938 17.4227C10.8604 16.9222 11.077 16.3272 11.5775 16.0938C12.0781 15.8604 12.673 16.0769 12.9065 16.5775C13.1399 17.078 12.9233 17.673 12.4228 17.9064C11.9222 18.1398 11.3272 17.9232 11.0938 17.4227Z" fill="#474F5C" />
    <path d="M11.3573 16.2344C11.7804 15.8794 12.4112 15.9345 12.7662 16.3576C13.1212 16.7807 13.066 17.4115 12.6429 17.7665C12.2198 18.1215 11.5891 18.0663 11.2341 17.6432C10.8791 17.2201 10.9342 16.5894 11.3573 16.2344Z" fill="#474F5C" />
    <defs>
      <linearGradient id="paint0_linear_2441_28582" x1="0.97389" y1="18" x2="23.0261" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DA2531" />
        <stop offset="0.214216" stopColor="#F19663" />
        <stop offset="0.379133" stopColor="#FFDC83" />
        <stop offset="0.597249" stopColor="#BAD88B" />
        <stop offset="0.791426" stopColor="#03CD9F" />
        <stop offset="1" stopColor="#018265" />
      </linearGradient>
    </defs>
  </svg>
);

export default FairScoreIcon;
