import React from 'react';

const BusinessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Featured icon">
      <path id="fox" d="M0 1.16023e-06V15.4083L16 28L32 15.4083V0L16 12.5917L0 1.16023e-06Z" fill="#67E8CB"/>
      <g id="Icon">
        <path id="Icon_2" d="M12.25 8.375V10.25H19.75V8.375C19.75 8.0625 19.4375 7.75 19.125 7.75H12.875C12.5234 7.75 12.25 8.0625 12.25 8.375ZM11 10.25V8.375C11 7.35938 11.8203 6.5 12.875 6.5H19.125C20.1406 6.5 21 7.35938 21 8.375V10.25H23.5C24.8672 10.25 26 11.3828 26 12.75V22.75C26 24.1562 24.8672 25.25 23.5 25.25H8.5C7.09375 25.25 6 24.1562 6 22.75V12.75C6 11.3828 7.09375 10.25 8.5 10.25H11ZM20.375 11.5H11.625H8.5C7.79688 11.5 7.25 12.0859 7.25 12.75V16.5H12.875H14.125H17.875H19.125H24.75V12.75C24.75 12.0859 24.1641 11.5 23.5 11.5H20.375ZM24.75 17.75H19.125V19.625C19.125 20.3281 18.5391 20.875 17.875 20.875H14.125C13.4219 20.875 12.875 20.3281 12.875 19.625V17.75H7.25V22.75C7.25 23.4531 7.79688 24 8.5 24H23.5C24.1641 24 24.75 23.4531 24.75 22.75V17.75ZM14.125 17.75V19.625H17.875V17.75H14.125Z" fill="#342876"/>
      </g>
    </g>
  </svg>
);

export default BusinessIcon;
